import { IonBackButton, IonBreadcrumb, IonBreadcrumbs, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonItem, IonLabel, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Node.css';
import {useRestLoad} from '../hooks/FreebookHttp'
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router';
import $ from 'jquery';
import { splitArray } from '../common/utils';

const Node: React.FC = () => {
  
  const {uuid} = useParams<{uuid: string}>()
  const [node,getNode] = useRestLoad('/rest/node/' );

  const location = useLocation();

  useEffect(()=>{
    getNode(uuid)
  },[uuid]);



  if (!node.ready){
    return <IonPage></IonPage>
  }else{


    const FILE_BASE_URL = window.location.origin.replace(':8100','') + '/files/'  

    return (
      <IonPage>
        <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" style={{paddingLeft:'16px',color:'#666666'}}/>
          </IonButtons>
          <IonTitle>{node.data.name}</IonTitle>
        </IonToolbar>
        <IonBreadcrumbs class='breadcrumbs'>
          <IonBreadcrumb routerLink={'/'} routerDirection="back" key={'home'}>首页</IonBreadcrumb>
          {
            node.data.paths.map((path:any)=>(
              <IonBreadcrumb routerLink={(location.pathname.startsWith('/node/')? '/node2/':'/node/')  + path.uuid} routerDirection="back" key={path.uuid}>{path.name}</IonBreadcrumb>
            ))
          }
          </IonBreadcrumbs>

        </IonHeader>
        <IonContent id="content">

          <IonGrid>
          { node.data.childs &&
            splitArray(node.data.childs,12).map((cols:any,i:any)=>(

              <IonRow key={i}>
                {
                  cols.map((child:any,j:any)=>(
                    <IonCol key={child.uuid || j} size="12" size-sm="6" size-md="4" size-lg="2">
                    <IonItem routerLink={(location.pathname.startsWith('/node/')? '/node2/':'/node/')  + child.uuid } key={child.uuid} detail>
                        <IonLabel>{child.name}</IonLabel>
                    </IonItem>
                    </IonCol>
                  ))
                }
              </IonRow>
            ))
            
          }

        </IonGrid>
          {
            node.data.has_html &&
            <div style={{overflow:'auto',height: '100%',width: '100%'}}> 
              <embed id="frame" src={FILE_BASE_URL + node.data.path + '.html' } width="100%" height="100%" />
            </div>
          }
          {
            node.data.has_jpg &&
            <div style={{overflow:'auto' ,width: '100%'}}> 
              <embed id="frame" src={FILE_BASE_URL + node.data.path + '.jpg' } width="100%" />
            </div>
          }
        </IonContent>
      </IonPage>
    );
  }
};

export default Node;
