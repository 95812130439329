import { useState, useEffect } from "react";


import axios from 'axios';



import config from "../config.json";
import { serverBaseUrl } from "../common/global";

axios.defaults.withCredentials = true;



const BASE_URL = serverBaseUrl //window.location.origin.replace('8100','8080')   //config["freebook.endport"]

const BOOK_URL = BASE_URL + "/rest/book"; 

const LOGIN_URL = BASE_URL + "/admin/rpc/login";
const LOGOUT_URL = BASE_URL + "/admin/rpc/logout";

const SEND_SMS_URL = BASE_URL + "/rpc/send-sms"; 
const SMS_LOGIN_URL = BASE_URL + "/rpc/sms-login"; 


const HISTORY_URL = BASE_URL + "/rpc/history";


interface Json {[key:string]:any};


const rpcUrls:Json = {
  "login":LOGIN_URL,
  "logout":LOGOUT_URL,
  "send-sms":SEND_SMS_URL
};


interface RpcReq{
  method:string, 
  args:{[key:string]:object},
}

export const restCall = (res:string,method:string, params?:Json, args?:Json ,
  scb?:(args:any)=>void, fcb?:(arg:string)=>void,ecb?:(arg:string)=>void)=>{

  let config:Json = {
    method:method,
    baseURL:BASE_URL,
    url: res.startsWith('/')?res: '/rest/' + res,
    withCredentials:true
  }

  if (params){
    config['params'] = params;
  }
  if (args){
    config['data'] = args;
  }

  axios(config).then(response => {

    console.log("===data=== " + JSON.stringify( response.data));

    if (scb){
      scb(response.data)

    }
  
  },response =>{
    console.log("===failure=== " + JSON.stringify( response));

    if (fcb){
      fcb(response.message);
    }
  }).catch(function (error) {
    console.log("===error=== " + error);
    
    if (ecb){
      ecb(error);
    }
  });

}

export const rpcCall = (method:string, args:Json,
  scb:(args:Json)=>void, fcb?:(arg:string)=>void,ecb?:(arg:string)=>void)=>{

  axios.post(rpcUrls[method],args,{
    withCredentials:true
  }).then(response => {

    console.log("===data=== " + JSON.stringify( response.data));

    scb(response.data)

  },response =>{
    console.log("===failure=== " + JSON.stringify( response));

    if (fcb){
      fcb(response.message);
    }
  }).catch(function (error) {
    console.log("===error=== " + error);
    
    if (ecb){
      ecb(error);
    }
  });

}

export const login = (args:Json,scb:(arg:Json)=>void,fcb?:(arg:string)=>void,ecb?:(arg:string)=>void)=>{

  rpcCall("login",args,scb,fcb,ecb);
}

export const logout = (scb:(arg:Json)=>void,fcb?:(arg:string)=>void,ecb?:(arg:string)=>void)=>{

  rpcCall("logout",{},scb,fcb,ecb);
}

export const sendSms = (args:Json,scb:(arg:Json)=>void,fcb?:(arg:string)=>void,ecb?:(arg:string)=>void)=>{
  rpcCall("send-sms",args,scb,fcb,ecb);

}


export const smsLogin = (mobile:string,code:string) => {

  console.log(">>> function smsLogin called.");

  return axios.post(SMS_LOGIN_URL,{
    mobile:mobile,
    code:code
  }).then(response => {

    console.log("===data=== " + JSON.stringify( response.data));
    return response.data;
  },response =>{
    console.log("===logic=== " + JSON.stringify( response));
    return response;
  }).catch(function (error) {
    console.log("===error=== " + error);
  });
};



export const history = ()=>{
  return axios.get(HISTORY_URL,{
    withCredentials:true
     
  }).then(response => {

    console.log("===data=== " + JSON.stringify( response.data));
    return response.data;
  }).catch(function (error) {
    console.log("===error=== " + error);
  });
}


// export const listOrders = ( filter?:{[key:string]:any},scb:resp=>void )=>{
//   return restCall('/rest/order','get',filter,scb);
// }

export const restDelete = (res:string,scb?:(args:any)=>void,fcb?:(args:any)=>void)=>{
    restCall(res,'delete',undefined,undefined,scb,fcb);
}

export const restPatch = (res:string,args?:Json,scb?:(args:any)=>void,fcb?:(args:any)=>void)=>{
    restCall(res,'patch',undefined,args,scb,fcb);
}

export const restPost = (res:string,params?:Json,args?:Json,scb?:(args:any)=>void,fcb?:(args:any)=>void)=>{
    restCall(res,'post',params,args,scb,fcb);
}

export const restPut = (res:string,params?:Json,args?:Json,scb?:(args:any)=>void,fcb?:(args:any)=>void)=>{
    restCall(res,'put',params,args,scb,fcb);
}

export const useRestGet = (res:string)=>{

  const [item,setItem] = useState<Json>({});

  const getItem = (params?:Json)=>{


    restCall(res,'get',params,undefined,resp=>{
      console.log('get item, resp:' + JSON.stringify(resp));
      setItem(resp);
    })
  }

  let ret:[Json,(args?:Json)=>void] = [item,getItem]
  

  return ret;  
}
export const useRestLoad = (res:string)=>{
  const [item,setItem] = useState<Json>({});

  const getItem = (id:String)=>{
    setItem({ready:false});
    restCall(res + id ,'get',undefined,undefined,resp=>{
      console.log('get item, resp:' + JSON.stringify(resp));
      setItem({
        ready:true,
        data:resp
      });
    })
  }

  let ret:[Json,(args:String)=>void] = [item,getItem]
  

  return ret;  
}

export const useRestList = (res:string)=>{
  const [items,setItems] = useState<[]>([]);

  const listItems = (params?:Json)=>{
    restCall(res,'get',params,undefined,resp=>{
      console.log('get item, resp:' + JSON.stringify(resp));
      setItems(resp);
    })
  }

  let ret:[[],(args?:Json)=>void] = [items,listItems]
  

  return ret;  
}


export const useRestSearch = (res:string)=>{
  const [items,setItems] = useState<Json>({page:0,size:0,pages:0,items:[]});

  const listItems = (filter:{[key:string]:any},page:number,size:number,fcb?:(args:any)=>void)=>{
    restCall(res,'get',{...filter,_page:page,_size:size},undefined,resp=>{
      console.log('list items, resp:' + JSON.stringify(resp));
      setItems(resp);
    },fcb)
  }

  let ret:[Json,(arg:any,page:number,size:number,fcb?:(args:any)=>void )=>void] = [items,listItems]
  

  return ret;
}


