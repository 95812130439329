import { IonCol, IonContent, IonGrid, IonHeader, IonItem, IonLabel, IonPage, IonRoute, IonRouterLink, IonRow, IonSearchbar, IonTitle, IonToolbar } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Home.css';
import {useRestList} from '../hooks/FreebookHttp'
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { splitArray } from '../common/utils';

const Home: React.FC = () => {
  

  const [nodes,listNodes] = useRestList('/rest/home');

  useEffect(()=>{
    listNodes()
  },[]);

  return (
    <IonPage>
      <IonHeader>

      <IonToolbar>
        <IonTitle>古文汇编</IonTitle>
        {/* <IonGrid fixed={true}>
          
          <IonSearchbar value={""} onIonChange={(e) => console.log(e.detail.value!)}
            animated={true} placeholder="搜索"
          >
          </IonSearchbar>

        </IonGrid> */}
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonGrid id='types'>
          <IonRow>
            <IonCol><Link className='type' to='/node/a1404f0c-40c8-425f-8f45-400d6aa3c28e'>子</Link></IonCol>
            <IonCol><Link className='type' to='/node/3a8be9d6-512e-4489-a7ed-0cd7c88150a3'>史</Link></IonCol>
            <IonCol><Link className='type' to='/node/731eca71-d5a9-469e-9866-756dc8395522'>易</Link></IonCol>
            <IonCol><Link className='type' to='/node/a4517bc5-42eb-4bdf-b05f-ce314e1eef68'>工</Link></IonCol>
            <IonCol><Link className='type' to='/node/935095fb-7415-49f0-b949-8b95b2bad851'>诗</Link></IonCol>
            <IonCol><Link className='type' to='/node/9a94c826-7672-48fb-b665-c1936d234578'>艺</Link></IonCol>
          </IonRow>
        </IonGrid>

        <IonGrid>
          { nodes &&
            splitArray(nodes,12).map((cols:any,i:any)=>(

              <IonRow key={i}>
                {
                  cols.map((child:any,j:any)=>(
                    <IonCol key={child.uuid || j} size="12" size-sm="6" size-md="4" size-lg="2">
                    <IonItem routerLink={'/node/'  + child.uuid } key={child.uuid} detail>
                        <IonLabel>{child.name}</IonLabel>
                    </IonItem>
                    </IonCol>
                  ))
                }
              </IonRow>
            ))
            
          }
        
        </IonGrid>

      </IonContent>
    </IonPage>
  );
};

export default Home;
